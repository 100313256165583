import React from 'react';
import { __ } from '../common/utils/LanguageHelper';
import { ThemeProvider } from 'styled-components';
import { saasTheme } from '../common/theme/saas';
import Sticky from 'react-stickynode';
import { GlobalStyle, ContentWrapper } from '../containers/saas.style';
import { DrawerProvider } from '../common/contexts/DrawerContext';
import Navbar from '../containers/Navbar';
import SEO from '../components/seo';
import { ResetCSS } from '../common/assets/css/style';
import Container from '../common/components/UI/Container';
import Paragraph from '../containers/Paragraph';

export default () => {
    return (
        <ThemeProvider theme={saasTheme}>
            <SEO
                title={__(
                    'سياسة الاستخدام والخصوصية',
                    'Usage and Privacy Policy'
                )}
            />
            <ResetCSS />
            <GlobalStyle />
            <ContentWrapper>
                <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                    <DrawerProvider>
                        <Navbar />
                    </DrawerProvider>
                </Sticky>

                <Container
                    style={{
                        marginTop: 150,
                    }}
                >
                    <Paragraph title={__('مقدمة', 'Introduction')}>
                        {__(
                            <>
                                تطبيق أواصل AWASUL يسعى دائمًا إلى الحفاظ على
                                خصوصية بياناتك الشخصية ونمتثل في ذلك لأحكام
                                قوانين حماية البيانات والحق في الخصوصية وذلك
                                بشأن حماية بياناتك الشخصية، وقد قمنا بصياغة هذه
                                السياسة "سياسة الخصوصية" لنوضح لك ماهية البيانات
                                التي يتم جمعها عنك، والأغراض التي من أجلها يتم
                                استخدام هذه البيانات، لذا يرجى قراءة هذه السياسة
                                بعناية. يشير مصطلح البيانات إلى كافة البيانات
                                المتعلقة بمستخدمي تطبيق أواصل والتي تشمل الاسم
                                والعنوان وبيانات الاتصال خاصة بهم وأي بيانات
                                أخرى من شأنها التعريف بهوية المستخدم كذلك
                                البيانات الأخرى المتعلقة بأنشطة المستخدم من خلال
                                التطبيق والبيانات المرتبطة بالأجهزة المستخدمة في
                                عملية الدخول إلى التطبيق.
                            </>,
                            `Awasul application seeks to maintain the privacy of your personal data, and we are complying with the provisions of the Data Protection and the right to Privacy Laws regarding the protection of your personal data, and we have formulated this "Privacy Policy" to show you the nature of the data  collected about you, and the purposes for which this data is used, so please read this policy carefully.
                            The term data refers to all the data relating to the users of Awasul application, which includes their name, address, contact details and any other data that identify the user as well as other data related to the user's activities through the application and data related to the devices used in the process of accessing the application.`
                        )}
                    </Paragraph>

                    <Paragraph
                        title={__(
                            'البيانات الشخصية التي نجمعها',
                            'Personal data we collect'
                        )}
                    >
                        <ul>
                            <li>
                                {__(
                                    <>
                                        نجمع البيانات التي قدمها العميل لتسجيل
                                        حسابه بالتطبيق والتي تشمل(الاسم الأول،
                                        الاسم الأخير، البريد الالكتروني، اختيار
                                        الدولة، رقم الجوال).
                                    </>,
                                    `We collect the data provided by the client to register his account on the application which includes (the first name, the last name, the email, the country_id choice and mobile number).`
                                )}
                            </li>
                            <li>
                                {__(
                                    <>
                                        البيانات التي تقدمها عن طريق ملء
                                        الاستمارات على التطبيق، ويشمل ذلك
                                        المعلومات المقدمة وقت التسجيل أو وقت نشر
                                        المواد أو عند طلب المزيد من الخدمات.
                                    </>,
                                    `Data that you provide by filling in forms on the application. This includes information provided at the time of registration or at the time of posting material or requesting further services.`
                                )}
                            </li>
                            <li>
                                {__(
                                    <>
                                        قد نطلب منك أيضًا معلومات عندما تدخل
                                        مسابقة أو ترويجًا يرعاه تطبيق أواصل.
                                    </>,
                                    `We may also ask you for information when you enter a competition or promotion sponsored by Awasul application.`
                                )}
                            </li>
                            <li>
                                {__(
                                    <>
                                        بيانات الدفع الإلكتروني الخاصة بالعميل.
                                    </>,
                                    `The data added by the user to his personal file and the content published by him via the application.`
                                )}
                            </li>
                            <li>
                                {__(
                                    <>
                                        يمنحنا المستخدم ترخيص غير حصري وغير
                                        محدود وغير قابل للإلغاء باستخدام
                                        معلوماته الشخصية المقدمة منه في استمارة
                                        التسجيل الالكترونية، وذلك لأغراض تقديم
                                        الخدمات، أو تحديثها أو إبلاغه بأمور
                                        تتعلق بتقديم خدمات التطبيق.
                                    </>,
                                    `The user shall grant us a non-exclusive, unlimited and irrevocable license to use his personal information submitted by him in the registration form online for the purposes of providing services, updating them or notifying him of matters connected with providing the services of the application.`
                                )}
                            </li>
                            <li>
                                {__(
                                    <>
                                        البيانات التي يضيفها المستخدم لملفه
                                        الشخصي، والمحتوى الذي يقوم بنشره من خلال
                                        التطبيق.
                                    </>,
                                    `The date of the electronic payment of the client.`
                                )}
                            </li>
                        </ul>
                    </Paragraph>

                    <Paragraph title={__('المحتوى', 'Content')}>
                        <ul>
                            <li>
                                {__(
                                    <>
                                        يزودنا العميل بمحتوى الرسائل والفيديوهات
                                        والصور والتعليقات من خلال التطبيق على
                                        مسئوليته الشخصية.
                                    </>,
                                    `The client provides us with the content of messages, videos, photos and comments through the application at his personal responsibility.`
                                )}
                            </li>
                            <li>
                                {__(
                                    <>
                                        يزودنا المستخدم بمحتوى الرسائل التي يقوم
                                        بإرسالها إلى التطبيق للاستفسار عن محتوى
                                        الخدمات أو الباقات.
                                    </>,
                                    `The user provides us with the content of the messages he sends to the application to inquire about the content of the services or packages.`
                                )}
                            </li>
                            <li>
                                {__(
                                    <>
                                        نجمع البيانات التي تقدمها لنا للإبلاغ عن
                                        التعليقات أو المشكلات التي تخالف الشروط
                                        والسياسات الخاصة بتطبيق أواصل.
                                    </>,
                                    `We collect the data you provide to us to report on comments or problems that violate the terms and policies of Awasul application.`
                                )}
                            </li>
                        </ul>
                    </Paragraph>

                    <Paragraph
                        title={__(
                            'بيانات الاتصال',
                            'Data collected automatically'
                        )}
                    >
                        <ul>
                            <li>
                                {__(
                                    <>
                                        يزودنا العميل برقم وبيانات الاتصال به،
                                        عندما يقوم بالاتصال بنا من خلال التطبيق
                                        سواء عبر التطبيق أو عبر البريد
                                        الالكتروني أو عبر الهاتف.
                                    </>,
                                    `The client provides us with his number and his contact details when he contacts us through the application, whether via the application, email or phone.`
                                )}
                            </li>
                            <li>
                                {__(
                                    <>
                                        نتيح للمستخدم التواصل مع التطبيق، وفي
                                        هذه الحالة يقدم لنا العميل المعلومات
                                        الآتية (الاسم، البريد الالكتروني، موضوع
                                        الرسالة، محتوى الرسالة).
                                    </>,
                                    `We allow the user to communicate with the application, in this case the client provides us with the following information (name, email, subject of the message, content of the message).`
                                )}
                            </li>
                            <li>
                                {__(
                                    <>
                                        نجمع البيانات التي تقدمها لنا أثناء
                                        التواصل معنا للاستفسار عن أي من خدماتنا
                                        أو مواجهة المشكلات التي تواجه استخدامك
                                        للتطبيق.
                                    </>,
                                    `We collect the data you provide to us while communicating with us to inquire about any of our services or to encounter the problems that facing your use of the application.`
                                )}
                            </li>
                        </ul>
                    </Paragraph>

                    <Paragraph
                        title={__(
                            'البيانات التي يتم جمعها تلقائيا',
                            'Introduction'
                        )}
                    >
                        {__(
                            <>
                                يجمع تطبيق أواصل بعض البيانات الخاصة
                                بالمستخدمين، والتي لا تحدد هوية المستخدم أو
                                بيانات الاتصال به، ويتم جمع هذه البيانات بشكل
                                تلقائي والتي تشمل على سبيل المثال (بيانات
                                الأجهزة المستخدمة في عمليات الاتصال، ووقت
                                الاتصال، ومدته، ومكان الاتصال، وعنوان IP،
                                الصفحات التي يتم زيارتها، فئات الإعلانات التي
                                يستهدفها المستخدم).
                            </>,
                            `Awasul application collects some data for the users, which does not identify the user or its contact data, and is automatically collected, which includes, for example (data of the devices used in contacts, time of contact, its duration, the location of contact, IP address, pages visited, advertisement categories that the user target).`
                        )}
                    </Paragraph>

                    <Paragraph
                        title={__(
                            'ملفات تعريف الارتباط (سياسة الكوكيز)',
                            'Cookies Policy'
                        )}
                    >
                        {__(
                            <>
                                يعتمد تطبيق أواصل AWASUL تقنية ملفات تعريف
                                الارتباط "الكوكيز" والتي هي عبارة عن ملفات صغيرة
                                تخزّن ضمن القرص الصلب من جهاز الكمبيوتر الخاص
                                بك. وعندما تقوم بزيارة صفحات إلكترونية محددة من
                                تطبيقنا الإلكتروني، تقوم ملفات الكوكيز بتعريف
                                المتصفح الخاص بك من خلال رقم مميز وعشوائي دون أن
                                تكشف عن أي معلومات شخصية متعلقة بك. تساعدنا
                                ملفات الكوكيز على تحسين تجربتك في استخدام
                                التطبيق ومعرفة الأقسام التي تحظى بأكبر نسبة
                                زيارة من تطبيقنا.
                            </>,
                            `Awasul application adopts the cookies technology, which are small files stored within the hard disk of your computer. When you visit specific web pages from our application, the cookies identify your browser through a unique and random number without disclosing any personal information about you. The cookies help us to improve your experience of using the application and finding out which sections have the largest share of visit from our application.`
                        )}
                    </Paragraph>

                    <Paragraph title={__('الأطراف الثالثة', 'Third Parties')}>
                        {__(
                            <>
                                قد يطبق على المستخدم شروط وأحكام خاصة بالأطراف
                                الثالثة أو التجار وهذه لا تخضع لسيطرتنا وبالتالي
                                يجب على المستخدم الإطلاع على هذه السياسات
                                والموافقة عليها قبل الاستفادة من الخدمات
                                والمنتجات التي يقدمها الأطراف الثالثة أو التجار.
                            </>,
                            `Terms and conditions of third parties or merchants may be applied to the user, and these are not subject to our control and therefore the user must access to and agree on these policies before benefiting from  the services or products provided by the third parties or merchants.
                            `
                        )}
                    </Paragraph>

                    <Paragraph
                        title={__(
                            'أغراض جمع البيانات',
                            'Purposes of Collecting Data'
                        )}
                    >
                        <ul>
                            <li>
                                {__(
                                    <>
                                        نجمع البيانات لتسجيل حسابك بالتطبيق،
                                        لتتمكن من استخدام خدماتنا والمزايا
                                        المختلفة التي نوفرها.
                                    </>,
                                    `We collect data to register your account on the application, so that you can use our services and the various features we offer.`
                                )}
                            </li>
                            <li>
                                {__(
                                    <>
                                        نجمع بياناتك لتمكينك من إضافة التعليقات
                                        من خلال التطبيق.
                                    </>,
                                    `We collect your data to enable you to add comments through the application.`
                                )}
                            </li>
                            <li>
                                {__(
                                    <>
                                        نجمع البيانات التي تقدمها لنا أثناء
                                        التواصل معنا للاستفسار عن أي من خدماتنا
                                        أو مواجهة المشكلات التي تواجه استخدامك
                                        للتطبيق.
                                    </>,
                                    `We collect your data for the purposes of documenting the contact details you have provided us with , and verifying your possession of them.`
                                )}
                            </li>
                            <li>
                                {__(
                                    <>
                                        نجمع بياناتك لأغراض تصميم البرنامج
                                        وتزويدك بالباقات التي تطلبها من خلال
                                        التطبيق أو تمكينك من الدفع الإلكتروني
                                        عبر التطبيق واسترداد الأموال وفقًا
                                        لأحكام الاتفاقية.
                                    </>,
                                    `We collect your data for programme design purposes and provide you with the packages you request through the application or enable you from electronic payment via the application and recovery of funds in accordance with the terms of the agreement.`
                                )}
                            </li>
                            <li>
                                {__(
                                    <>
                                        نجمع بياناتك لتمكينك من تقييم الخدمات
                                        التي تحصل عليها من خلال التطبيق.
                                    </>,
                                    `We collect your data to enable you to assess the services you get through the application.`
                                )}
                            </li>
                            <li>
                                {__(
                                    <>
                                        نجمع بياناتك لتمكينك من الاتصال بنا
                                        وتقديم الردود على الاستفسارات التي
                                        قدمتها لنا من خلال التطبيق.
                                    </>,
                                    `We collect your data to enable you to contact us and provide replies to inquiries you have provided to us through the application.`
                                )}
                            </li>
                            <li>
                                {__(
                                    <>
                                        نجمع بياناتك لتخصيص المحتوى والإعلانات
                                        لك من خلال التطبيق.
                                    </>,
                                    `We collect your data to individualize the content and the advertisements for you through the application.`
                                )}
                            </li>
                            <li>
                                {__(
                                    <>
                                        نجمع بياناتك لترشيح الإعلانات والمنتجات
                                        التي نعتقد أنها تثير اهتمامك.
                                    </>,
                                    `We collect your data to nominate the advertisements and the products that we believe are of interest to you.`
                                )}
                            </li>
                            <li>
                                {__(
                                    <>
                                        نجمع بياناتك للأغراض الإحصائية وأغراض
                                        العمل الداخلي بالتطبيق.
                                    </>,
                                    `We collect your data to nominate the advertisements and the products that we believe are of interest to you.`
                                )}
                            </li>
                            <li>
                                {__(
                                    <>
                                        نجمع بياناتك لأغراض تنفيذ القانون أو
                                        قرارات السلطة العامة.
                                    </>,
                                    `We collect your data for law enforcement purposes or public authority decisions.`
                                )}
                            </li>
                        </ul>
                    </Paragraph>

                    <Paragraph
                        title={__(
                            'حدود الكشف عن بياناتك',
                            'Limits of Data Disclosure'
                        )}
                    >
                        <ul>
                            <li>
                                {__(
                                    <>
                                        نكشف عن بياناتك لشركائنا في التطبيق
                                        والموظفين والعاملين لدينا ومقدمي الخدمات
                                        للعملاء لأغراض تنفيذ الخدمات خاصة بتطبيق
                                        أواصل.
                                    </>,
                                    `We disclose your data to our partners on the application , employees, those who work with us and client service providers for the purposes of implementing the services of Awasul application.`
                                )}
                            </li>
                            <li>
                                {__(
                                    <>
                                        نكشف عن بياناتك للالتزام بأحكام القانون
                                        وتنفيذًا للأحكام القضائية وقرارات الجهات
                                        المختصة بالمملكة.
                                    </>,
                                    `We disclose your data to comply with the provisions of the law and, to execute the judicial decisions and the decisions of the competent authorities in the Kingdom.`
                                )}
                            </li>
                            <li>
                                {__(
                                    <>
                                        نكشف عن بياناتك لتنفيذ شروطنا وأحكامنا
                                        وأي اتفاق آخر بين التطبيق وبينك.
                                    </>,
                                    `We disclose your data to implement our terms, conditions and any other agreement between you and the application.`
                                )}
                            </li>
                            <li>
                                {__(
                                    <>
                                        قد نكشف عن بياناتك للجهات التي تشاركنا
                                        تقديم الخدمات كجهات الدفع الالكتروني
                                        وذلك لأغراض التأكد من صحة هذه العمليات.
                                    </>,
                                    `We may disclose your data to our co-providers as electronic payment providers for the purposes of verifying the validity of these transactions.`
                                )}
                            </li>
                        </ul>
                    </Paragraph>

                    <Paragraph
                        title={__('تخزين البيانات', 'Storage of The Data')}
                    >
                        {__(
                            <>
                                نقوم بتخزين كافة بياناتك على خوادم محمية بشكل
                                كامل ومؤمنة لحماية بياناتك من أي اختراق أو عملية
                                قرصنة، وقد يتطلب تخزين بياناتك نقلها خارج
                                المملكة العربية السعودية في حالة استعانة التطبيق
                                بمزود خدمة استضافة خارجي، لذلك فأنت تفوضنا
                                بالتعامل مع الآخرين بشأن بياناتك كما تفوضنا في
                                نقلها وتخزينها على الخوادم التي نتعامل معها.
                            </>,
                            `We application all your data on fully protected and secure servers to protect your data from any hacking or piracy, and the storage of your data may require its transferring outside the Kingdom of Saudi Arabia in case the application utilizes a hosting service provider.`
                        )}
                    </Paragraph>

                    <Paragraph
                        title={__(' سلامة البيانات', 'Integrity of The Data')}
                    >
                        {__(
                            <>
                                أنت تعلم أن الانترنت ليس وسيلة آمنة ومضمونة
                                بنسبة 100%، لذلك قد يحدث اختراق لقواعد بياناتنا
                                رغم اتخاذنا الإجراءات السليمة لحماية البيانات،
                                ومن منطلق احترامنا لحقك في حماية بياناتك سوف
                                نقوم بإخطارك خلال ثلاثة أيام من وقت علمنا
                                بالاختراق
                            </>,
                            `You know that the Internet is not a 100% safe and secure mean, so our databases may be hacked into even though we have taken proper data protection procedures. From the perspective of our respect for your right to protect your data we will notify you within 3 days of the time we know about the hacking.`
                        )}
                    </Paragraph>

                    <Paragraph
                        title={__(
                            ' تعديل سياسة الخصوصية',
                            'Amendment of The Privacy Policy'
                        )}
                    >
                        {__(
                            <>
                                يحق لنا تعديل سياسة الخصوصية هذه في أي وقت،
                                وسنقوم بنشر التعديلات ضمن هذه الوثيقة كما سنقوم
                                بإرسال إشعار عام عبر البريد الالكتروني المسجل
                                لدينا نوضح فيه رابط التعديلات الجديدة، ويعد
                                استمرارك في استخدام التطبيق بعد تعديلات هذه
                                السياسة موافقة صريحة منك على هذه التعديلات، لذا
                                يرجى مراجعة هذه السياسة باستمرار.
                            </>,
                            `We have the right to amend this privacy policy at any time, and we will publish the amendments within this document. Also we will send a public notification via our registered email explaining the link to the new amendments. And your continued use of the application after the amendments of this policy is considered as an express consent of you on these amendments, so please review this policy constantly.`
                        )}
                    </Paragraph>

                    <Paragraph title={__('الاتصال بنا', 'Contact Us')}>
                        {__(
                            <td>يمكنك التواصل معنا عبر:</td>,
                            <td>You can contact us through:</td>
                        )}
                        <dd>
                            <ul>
                                <li>
                                    {__(
                                        'البريد الإلكتروني  info@awasul.sa',
                                        'Email: info@awasul.sa'
                                    )}
                                </li>
                                <li>
                                    {__(
                                        'الاتصال بنا على الرقم +966126825858',
                                        'Call us on the number: +966126825858'
                                    )}
                                </li>
                            </ul>
                        </dd>
                    </Paragraph>
                </Container>
            </ContentWrapper>
        </ThemeProvider>
    );
};
